import { FC } from 'react';

import cn from 'classnames';

import styles from '@/components/ContactUs/ContactUsForm.module.scss';

type Props = {
	locale: string;
	translate: (arg: string) => string;
};

export const Contacts: FC<Props> = ({ locale, translate }) => {
	return (
		<div>
			<h2
				id="openoffice"
				className={cn(
					styles.sectionTitle,
					locale === 'ru' ? 'max-w-[40rem]' : 'max-w-[50rem]',
				)}
			>
				{translate('Открыть')} <br />
				{translate('цифровой офис')}
			</h2>
			<p className={styles.sectionSubtitle}>{translate('Позвоните нам')}</p>
			<a className={styles.tel} href="tel:+79089998080">
				+7 908 999 80 80
			</a>
			<ul className={styles.socials}>
				<li>
					<a href="https://t.me/fishplaceru" target="_blank" rel="noreferrer">
						<img src="/img/socials/telegram.svg" alt="Telegram" />
					</a>
				</li>
				<li>
					<a
						href="https://api.whatsapp.com/send?phone=79089998080"
						target="_blank"
						rel="noreferrer"
					>
						<img src="/img/socials/whatsapp.svg" alt="WhatsApp" />
					</a>
				</li>
				<li>
					<a href="mailto:info@fishplace.ru" target="_blank" rel="noreferrer">
						<img src="/img/socials/email.svg" alt="Email" />
					</a>
				</li>
			</ul>
		</div>
	);
};
