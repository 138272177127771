export const en = {
	Открыть: 'Open',
	'цифровой офис': 'a digital office',
	'Позвоните нам': 'Call us at',
	'Связаться с нами': 'Contact us',
	'Как вас зовут?': 'What is your name?',
	'Иван Иванов': 'Ivanov Ivan',
	'Номер телефона': 'Phone number',
	Отправить: 'Send',
	'Нажимая на кнопку Отправить, вы соглашаетесь с': 'By clicking “send” you consent to ',
	'Политикой конфиденциальности': 'Privacy Policy',
	и: 'and',
	'Пользовательским соглашением': 'Terms of use',
	'Отправлено!': 'Sent!',
};
