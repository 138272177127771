export const FishBgLarge = () => (
	<svg className="hide-sm" width="122.4rem" height="52.1rem" fill="none" viewBox="0 0 1224 521">
		<g stroke="#fff" strokeLinejoin="round" strokeWidth="3.209" opacity=".1">
			<path d="M453.032 23.728c.709-12.942 25.257-28.011 36.485-12.327 1.572 12.172-22.731 25.464-36.485 12.327Zm0 0s-5.268 14.483-7.549 15.254c-.853.288-1.075-1.414-1.019-3.657.141-5.635-1.91-11.051-5.512-15.387-1.489-1.793-2.336-3.366-1.173-3.759 3.04-1.027 15.253 7.55 15.253 7.55Z" />
			<path strokeLinecap="round" d="M477.859 14.493c-1.028-3.04 3.533-4.58 4.56-1.54" />
		</g>
		<g stroke="#fff" strokeLinejoin="round" strokeWidth="3.209" opacity=".1">
			<path d="M18.735 448.337c9.444-8.878 37.61-2.843 34.911 16.256-7.262 9.894-34.022 2.743-34.91-16.256Zm0 0s-13.808 6.846-15.99 5.83c-.816-.38.198-1.765 1.787-3.35 3.99-3.981 6.243-9.317 6.628-14.94.16-2.325.633-4.048 1.745-3.53 2.91 1.355 5.83 15.99 5.83 15.99Z" />
			<path strokeLinecap="round" d="M43.075 458.786c1.355-2.91 5.719-.878 4.364 2.032" />
		</g>
		<g stroke="#fff" strokeLinejoin="round" strokeWidth="3.209" opacity=".1">
			<path d="M1183.4 504.042c1.74-12.845 27.41-25.902 37.35-9.369.59 12.258-24.69 23.564-37.35 9.369Zm0 0s-6.41 14.015-8.75 14.601c-.87.219-.96-1.495-.72-3.727.59-5.606-1.02-11.169-4.26-15.779-1.35-1.906-2.06-3.542-.87-3.84 3.11-.781 14.6 8.745 14.6 8.745Z" />
			<path strokeLinecap="round" d="M1208.88 496.823c-.78-3.113 3.89-4.284 4.67-1.172" />
		</g>
		<g stroke="#fff" strokeLinejoin="round" strokeWidth="3.209" opacity=".1">
			<path d="M529.45 259.601c7.765-10.378 36.55-9.31 37.198 9.968-5.439 11.001-33.034 8.59-37.198-9.968Zm0 0s-12.414 9.133-14.739 8.51c-.87-.233-.111-1.772 1.179-3.609 3.241-4.611 4.536-10.256 3.943-15.861-.246-2.318-.079-4.097 1.107-3.779 3.1.831 8.51 14.739 8.51 14.739Z" />
			<path strokeLinecap="round" d="M555.231 265.679c.831-3.1 5.481-1.854 4.65 1.246" />
		</g>
	</svg>
);
export const FishBgSmall = () => (
	<svg
		className="sm-only"
		width="9.5rem"
		height="19rem"
		fill="none"
		viewBox="0 0 95 190"
		style={{ top: '1rem', right: '2rem' }}
	>
		<g stroke="#fff" strokeLinejoin="round" strokeWidth="3.209" opacity=".1">
			<path d="M54.533 16.979c7.765-10.379 36.55-9.311 37.199 9.967-5.44 11.002-33.035 8.59-37.199-9.967Zm0 0s-12.414 9.133-14.739 8.51c-.87-.233-.11-1.773 1.18-3.61 3.24-4.61 4.536-10.256 3.942-15.86-.245-2.318-.078-4.097 1.108-3.78 3.1.831 8.51 14.74 8.51 14.74Z" />
			<path strokeLinecap="round" d="M80.315 23.057c.83-3.1 5.48-1.854 4.65 1.245" />
		</g>
		<g stroke="#fff" strokeLinejoin="round" strokeWidth="3.209" opacity=".1">
			<path d="M35.672 169.934c-6.87-9.184-32.342-8.239-32.916 8.82 4.814 9.735 29.232 7.602 32.916-8.82Zm0 0s10.986 8.081 13.043 7.53c.627-.168.298-1.102-.463-2.321-2.983-4.782-4.206-10.317-3.99-15.95.057-1.51-.197-2.533-1.06-2.302-2.742.735-7.53 13.043-7.53 13.043Z" />
			<path strokeLinecap="round" d="M12.859 175.312c-.735-2.743-4.85-1.641-4.115 1.102" />
		</g>
	</svg>
);
export const FishFormLarge = () => (
	<svg className="hide-sm" width="34.1rem" height="2.8rem" fill="none" viewBox="0 0 341 28">
		<g stroke="#fff" strokeLinejoin="round" strokeWidth="3.209" opacity=".1">
			<path d="M14.857 14.034C19.67 2 47.75-4.418 53.367 14.034c-2.407 12.035-29.685 16.848-38.51 0Zm0 0S5.229 26.07 2.822 26.07c-.9 0-.566-1.683.206-3.791 1.937-5.293 1.727-11.081-.298-16.341C1.893 3.76 1.594 2 2.822 2c3.21 0 12.035 12.034 12.035 12.034Z" />
			<path strokeLinecap="round" d="M41.333 13.232c0-3.209 4.814-3.209 4.814 0" />
		</g>
		<g stroke="#fff" strokeLinejoin="round" strokeWidth="3.209" opacity=".1">
			<path d="M86.224 14.034c4.814-12.034 32.894-18.453 38.51 0-2.406 12.035-29.685 16.848-38.51 0Zm0 0S76.596 26.07 74.189 26.07c-.9 0-.565-1.683.206-3.791 1.937-5.293 1.727-11.081-.297-16.341C73.26 3.76 72.962 2 74.188 2c3.21 0 12.035 12.034 12.035 12.034Z" />
			<path strokeLinecap="round" d="M112.7 13.232c0-3.209 4.814-3.209 4.814 0" />
		</g>
		<g stroke="#fff" strokeLinejoin="round" strokeWidth="3.209" opacity=".1">
			<path d="M157.591 14.034c4.814-12.034 32.895-18.453 38.511 0-2.407 12.035-29.686 16.848-38.511 0Zm0 0s-9.628 12.035-12.034 12.035c-.901 0-.566-1.683.205-3.791 1.937-5.293 1.727-11.081-.297-16.341-.837-2.176-1.136-3.937.092-3.937 3.209 0 12.034 12.034 12.034 12.034Z" />
			<path strokeLinecap="round" d="M184.067 13.232c0-3.209 4.814-3.209 4.814 0" />
		</g>
		<g stroke="#fff" strokeLinejoin="round" strokeWidth="3.209" opacity=".1">
			<path d="M228.958 14.034c4.814-12.034 32.895-18.453 38.511 0-2.407 12.035-29.685 16.848-38.511 0Zm0 0s-9.627 12.035-12.034 12.035c-.9 0-.566-1.683.205-3.791 1.937-5.293 1.727-11.081-.297-16.341-.837-2.176-1.136-3.937.092-3.937 3.209 0 12.034 12.034 12.034 12.034Z" />
			<path strokeLinecap="round" d="M255.434 13.232c0-3.209 4.814-3.209 4.814 0" />
		</g>
		<g stroke="#fff" strokeLinejoin="round" strokeWidth="3.209" opacity=".1">
			<path d="M300.325 14.034c4.814-12.034 32.895-18.453 38.511 0-2.407 12.035-29.685 16.848-38.511 0Zm0 0s-9.627 12.035-12.034 12.035c-.9 0-.566-1.683.205-3.791 1.937-5.293 1.727-11.081-.297-16.341-.837-2.176-1.136-3.937.092-3.937 3.209 0 12.034 12.034 12.034 12.034Z" />
			<path strokeLinecap="round" d="M326.802 13.232c0-3.209 4.813-3.209 4.813 0" />
		</g>
	</svg>
);

export const FishFormSmall = () => (
	<svg className="sm-only" width="32.2rem" height="2.2rem" viewBox="0 0 342 22" fill="none">
		<g opacity="0.1">
			<path
				d="M11.511 10.7988C15.0722 1.79854 35.8454 -3.00147 40 10.7988C38.2194 19.7988 18.0398 23.3985 11.511 10.7988ZM11.511 10.7988C11.511 10.7988 4.38879 19.7988 2.60824 19.7988C2.30336 19.7988 2.18989 19.535 2.21123 19.0976C2.48325 13.5209 2.07802 8.19486 2.0002 2.61203C1.99318 2.10812 2.16813 1.79883 2.60824 1.79883C4.98232 1.79883 11.511 10.7988 11.511 10.7988Z"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinejoin="round"
			/>
			<path
				d="M31.0972 10.1989C31.0972 7.79888 34.6583 7.79893 34.6583 10.1989"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<g opacity="0.1">
			<path
				d="M61.511 10.7988C65.0722 1.79854 85.8454 -3.00147 90 10.7988C88.2194 19.7988 68.0398 23.3985 61.511 10.7988ZM61.511 10.7988C61.511 10.7988 54.3888 19.7988 52.6082 19.7988C52.3034 19.7988 52.1899 19.535 52.2112 19.0976C52.4833 13.5209 52.078 8.19486 52.0002 2.61203C51.9932 2.10812 52.1681 1.79883 52.6082 1.79883C54.9823 1.79883 61.511 10.7988 61.511 10.7988Z"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinejoin="round"
			/>
			<path
				d="M81.0972 10.1989C81.0972 7.79888 84.6583 7.79893 84.6583 10.1989"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<g opacity="0.1">
			<path
				d="M111.511 10.7988C115.072 1.79854 135.845 -3.00147 140 10.7988C138.219 19.7988 118.04 23.3985 111.511 10.7988ZM111.511 10.7988C111.511 10.7988 104.389 19.7988 102.608 19.7988C102.303 19.7988 102.19 19.535 102.211 19.0976C102.483 13.5209 102.078 8.19486 102 2.61203C101.993 2.10812 102.168 1.79883 102.608 1.79883C104.982 1.79883 111.511 10.7988 111.511 10.7988Z"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinejoin="round"
			/>
			<path
				d="M131.097 10.1989C131.097 7.79888 134.658 7.79893 134.658 10.1989"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<g opacity="0.1">
			<path
				d="M161.511 10.7988C165.072 1.79854 185.845 -3.00147 190 10.7988C188.219 19.7988 168.04 23.3985 161.511 10.7988ZM161.511 10.7988C161.511 10.7988 154.389 19.7988 152.608 19.7988C152.303 19.7988 152.19 19.535 152.211 19.0976C152.483 13.5209 152.078 8.19486 152 2.61203C151.993 2.10812 152.168 1.79883 152.608 1.79883C154.982 1.79883 161.511 10.7988 161.511 10.7988Z"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinejoin="round"
			/>
			<path
				d="M181.097 10.1989C181.097 7.79888 184.658 7.79893 184.658 10.1989"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<g opacity="0.1">
			<path
				d="M211.511 10.7988C215.072 1.79854 235.845 -3.00147 240 10.7988C238.219 19.7988 218.04 23.3985 211.511 10.7988ZM211.511 10.7988C211.511 10.7988 204.389 19.7988 202.608 19.7988C202.303 19.7988 202.19 19.535 202.211 19.0976C202.483 13.5209 202.078 8.19486 202 2.61203C201.993 2.10812 202.168 1.79883 202.608 1.79883C204.982 1.79883 211.511 10.7988 211.511 10.7988Z"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinejoin="round"
			/>
			<path
				d="M231.097 10.1989C231.097 7.79888 234.658 7.79893 234.658 10.1989"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<g opacity="0.1">
			<path
				d="M261.511 10.7988C265.072 1.79854 285.845 -3.00147 290 10.7988C288.219 19.7988 268.04 23.3985 261.511 10.7988ZM261.511 10.7988C261.511 10.7988 254.389 19.7988 252.608 19.7988C252.303 19.7988 252.19 19.535 252.211 19.0976C252.483 13.5209 252.078 8.19486 252 2.61203C251.993 2.10812 252.168 1.79883 252.608 1.79883C254.982 1.79883 261.511 10.7988 261.511 10.7988Z"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinejoin="round"
			/>
			<path
				d="M281.097 10.1989C281.097 7.79888 284.658 7.79893 284.658 10.1989"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<g opacity="0.1">
			<path
				d="M311.511 10.7988C315.072 1.79854 335.845 -3.00147 340 10.7988C338.219 19.7988 318.04 23.3985 311.511 10.7988ZM311.511 10.7988C311.511 10.7988 304.389 19.7988 302.608 19.7988C302.303 19.7988 302.19 19.535 302.211 19.0976C302.483 13.5209 302.078 8.19486 302 2.61203C301.993 2.10812 302.168 1.79883 302.608 1.79883C304.982 1.79883 311.511 10.7988 311.511 10.7988Z"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinejoin="round"
			/>
			<path
				d="M331.097 10.1989C331.097 7.79888 334.658 7.79893 334.658 10.1989"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
	</svg>
);
