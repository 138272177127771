import React from 'react';

import { GetServerSideProps } from 'next';

import { getTariffs } from '@/api/tariff/get-tariffs';
import { ContactUs } from '@/components/ContactUs/ContactUs';
import { Footer } from '@/components/Footer/Footer';
import {
	FishSearch,
	HeroHeader,
	StartSale,
	Tariffs,
	WarehouseSearch,
	WarehousesStatistic,
} from '@/components/MainLanding';
import PageScroll from '@/components/PageScroll';
import { DocsLinks } from '@/types/legal';
import { Tariff } from '@/types/tariff';

type Props = {
	docs: DocsLinks;
	tariffs?: Tariff.TariffsInfoResponse;
};

export default function MainLanding({ docs, tariffs }: Props) {
	return (
		<>
			<PageScroll>
				<PageScroll.Item id="hero-header" mode="dark">
					<HeroHeader />
				</PageScroll.Item>

				<PageScroll.Item id="warehouses-search">
					<WarehouseSearch />
				</PageScroll.Item>

				<PageScroll.Item id="fish-search" mode="dark">
					<FishSearch />
				</PageScroll.Item>

				<PageScroll.Item id="warehouses-statistic">
					<WarehousesStatistic />
				</PageScroll.Item>

				<PageScroll.Item id="start-sale">
					<StartSale />
				</PageScroll.Item>

				<PageScroll.Item id="tariffs" mode="dark">
					<Tariffs tariffsData={tariffs} />
				</PageScroll.Item>

				<PageScroll.Item id="contact-us" mode="dark">
					<ContactUs docs={docs} />
				</PageScroll.Item>
			</PageScroll>

			<Footer docs={docs} />
		</>
	);
}

export const getServerSideProps: GetServerSideProps = async (context) => {
	context.res.setHeader('Cache-Control', 'no-cache, no-store, max-age=0, must-revalidate');

	try {
		const tariffs = await getTariffs({ lang: context.locale });

		return {
			props: {
				tariffs,
			},
		};
	} catch (error) {
		return {
			props: {
				tariffs: null,
			},
		};
	}
};
