import { FC, FormEvent, useRef, useState } from 'react';

import { FishFormLarge, FishFormSmall } from '@/components/ContactUs/icons';
import { FormResults } from '@/components/ContactUs/types';
import Button from '@/UI/Button';
import LabeledInput from '@/UI/LabeledInput';

import styles from './ContactUsForm.module.scss';

type Props = {
	loading: boolean;
	onSubmit: (args: FormResults) => Promise<void>;
	translate: (arg: string) => string;
	privacyPolicyLink: string;
	userAgreementLink: string;
};
export const Form: FC<Props> = ({
	loading,
	onSubmit,
	privacyPolicyLink,
	translate,
	userAgreementLink,
}) => {
	const [name, setName] = useState<string>('');
	const [phone, setPhone] = useState<string>('');
	const formRef = useRef<HTMLFormElement>(null);

	const resetForm = () => {
		setName('');
		setPhone('');
	};

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();
		try {
			await onSubmit({
				name,
				phone,
			});
			resetForm();
		}
		catch (e) {}
	};

	return (
		<form
			id="contact-us"
			className={styles.form}
			action="#"
			onSubmit={handleSubmit}
			ref={formRef}
		>
			<FishFormLarge />
			<FishFormSmall />

			<h3>{translate('Связаться с нами')}</h3>
			<LabeledInput
				label={translate('Как вас зовут?')}
				inputtype="text"
				placeholder={translate('Иван Иванов')}
				value={name}
				onChange={(event) => setName((event.target as HTMLInputElement).value)}
			/>
			<LabeledInput
				label={translate('Номер телефона')}
				inputtype="tel"
				value={phone}
				onChange={(event) => setPhone((event.target as HTMLInputElement).value)}
			/>

			<Button disabled={!name || !phone || loading}>{translate('Отправить')}</Button>
			<p className={styles.formAgreement}>
				{translate('Нажимая на кнопку Отправить, вы соглашаетесь с')}{' '}
				<a href={privacyPolicyLink} target="_blank" rel="noreferrer">
					{translate('Политикой конфиденциальности')}
				</a>{' '}
				{translate('и')}{' '}
				<a href={userAgreementLink} target="_blank" rel="noreferrer">
					{translate('Пользовательским соглашением')}
				</a>
			</p>
		</form>
	);
};
