import React, { HTMLProps, ReactElement, useEffect, useRef, useState } from 'react';

import Link from 'next/link';

import clsx from 'clsx';

import useMobile from '@/hooks/use-mobile';

import cls from './PageScroll.module.scss';

type PageScrollItemProps = HTMLProps<HTMLDivElement> & {
	mode?: 'light' | 'dark';
	id: string;
};

type PageScrollProps = {
	children: ReactElement<PageScrollItemProps> | ReactElement<PageScrollItemProps>[];
};

function PageScroll(props: PageScrollProps) {
	const isMobile = useMobile();
	const childrenRef = useRef();
	const [childrenIds, setChildrenIds] = useState<string[]>([]);
	const [currentId, setCurrentId] = useState<string>();
	const [currentMode, setCurrentMode] = useState<string>('light');
	const observerRef = useRef(null);

	useEffect(() => {
		if (isMobile || !childrenRef) return;

		const container = childrenRef?.current as HTMLDivElement;
		// Получаем дочерние элементы
		const children = [...container.children];

		// Сохраняем id дочерних элементов
		const ids = children.map((child) => child.id);
		setChildrenIds(ids);
		setCurrentId(ids[0]);

		// Создаём observer
		// Когда элемент попадает в область видимости, сохраняет еге id и фоновый цвет в state
		observerRef.current = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (!entry.isIntersecting) return;

					const section = entry.target;

					setCurrentMode(section.getAttribute('data-mode'));
					setCurrentId(section.getAttribute('id'));
				});
			},
			{
				rootMargin: '0px',
				threshold: 0.4,
			},
		);

		// Вешаем observer на каждый дочерний элемент
		children.forEach((child) => observerRef.current.observe(child));

		return () => {
			observerRef.current.disconnect();
		};
	}, [childrenRef, isMobile]);

	const scrollHandler = (id: string) => {
		document.getElementById(id).scrollIntoView({
			behavior: 'smooth',
		});
	};

	return (
		<main>
			<div ref={childrenRef} className={cls.childrens}>
				{props.children}
			</div>

			<ul className={clsx(cls.nav, currentMode === 'light' ? cls.dark : cls.light)}>
				{childrenIds?.map((id) => (
					<li key={id} className={cls.item}>
						<Link
							className={clsx(cls.link, currentId === id && cls.active)}
							onClick={(e) => {
								e.preventDefault();
								scrollHandler(id);
							}}
							href={`#${id}`}
						/>
					</li>
				))}
			</ul>
		</main>
	);
}

function PageScrollItem({ id, children, mode = 'light' }: PageScrollItemProps) {
	return (
		<div id={id} data-mode={mode}>
			{children}
		</div>
	);
}

PageScroll.Item = PageScrollItem;

export default PageScroll;
