/* eslint-disable @next/next/no-img-element */
import { FC, Suspense, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import axios from 'axios';

import { Contacts } from '@/components/ContactUs/Contacts';
import { Form } from '@/components/ContactUs/Form';
import { FormResults } from '@/components/ContactUs/types';
import { DEFAULT_DOCS_LINKS } from '@/constants/common';
import { useTranslate } from '@/hooks/use-translate';
import { DocsLinks } from '@/types/legal';
import Container from '@/UI/Container';

import { en } from './en';
import { FishBgLarge, FishBgSmall } from './icons';

import styles from './ContactUsForm.module.scss';

type Props = {
	docs: DocsLinks;
};
export const ContactUs: FC<Props> = ({ docs }) => {
	const { privacyPolicy, userAgreement } = docs ?? DEFAULT_DOCS_LINKS;

	const [loading, setLoading] = useState(false);
	const t = useTranslate(en);
	const router = useRouter();
	const { locale } = router;

	useEffect(() => {
		if (router.asPath.includes('#openoffice')) {
			const el = document.querySelector('#openoffice');
			el?.scrollIntoView({ block: 'start' });
		}
	}, []);

	const handleSubmit = async ({ name, phone }: FormResults) => {
		setLoading(true);
		try {
			await axios.post(`${process.env.HOST}/api_v1/connect-with-us/`, {
				name,
				phone,
			});
			alert(t('Отправлено!'));
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	if (!docs) {
		return <Suspense />;
	}

	return (
		<section className={styles.contactUsForm}>
			<Container>
				<div className={styles.container}>
					<div className={styles.contactUsFormInner}>
						<FishBgLarge />
						<FishBgSmall />

						<Contacts locale={locale} translate={t} />

						<Form
							loading={loading}
							onSubmit={handleSubmit}
							translate={t}
							privacyPolicyLink={privacyPolicy}
							userAgreementLink={userAgreement}
						/>
					</div>
				</div>
			</Container>
		</section>
	);
};
